import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Allmemoriam from './Allmemoriam'

import './memoriam.css'

const Memoriam = () => {

    const data = useStaticQuery(graphql`
    {
        allStrapiMemoriamCollecionTypes(sort: {fields: SortOrder, order: ASC}) {
          nodes {
            MemoriamDynamicZone {
              TitleAlignment
              TitleFontColor
              TitleFontFamily
              TitleFontSize
              TitleFontWeight
              TitleName
            }
            Date
            Description
            Name
            PageBGColor
            Slug
            SortOrder
            Image {
              childImageSharp {
                fixed(width: 125, height: 155) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
      `)

      var memoriam = data.allStrapiMemoriamCollecionTypes.nodes;
      var memoriam_titleName = memoriam[0].MemoriamDynamicZone[0];

      return (
        <div>
          <div class="card memoriam_card" style={{backgroundColor:memoriam[1].PageBGColor}}>
            <h1
              style={{
                textAlign: memoriam_titleName.TitleAlignment,
                color: memoriam_titleName.TitleFontColor,
                fontFamily: memoriam_titleName.TitleFontFamily,
                fontSize: memoriam_titleName.TitleFontSize,
                fontWeight: memoriam_titleName.TitleFontWeight,
              }}
            >
              {memoriam_titleName.TitleName}
            </h1>
    
            <div class="row">
              {memoriam.map(allmemoriam => {
                return <Allmemoriam {...allmemoriam} />
              })}
            </div>
          </div>
        </div>
      )
  }

export default Memoriam
