import React from 'react'
import './memoriam.css'
import Img from 'gatsby-image'
import { Link} from 'gatsby'

const Allmemoriam = ({ Date, Name, Image ,Slug, PageBGColor}) =>{

  return (
    <div class="single_box" style={{backgroundColor: PageBGColor}}>
      <div class="center" style={{ padding: '15px' }}>
        <Link to={`/memoriam/${Slug}`}>
          <Img fixed={Image.childImageSharp.fixed} />
          <p style={{ fontSize: '18px' }}>{Name}</p>
          <p style={{ fontSize: '15px' }}>{Date}</p>
        </Link>
      </div>
    </div>
  )
}

export default Allmemoriam
